import { Box, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { DEFAULT_BAR_ITEM_HEIGHT, DEFAULT_BAR_ITEM_WIDTH } from './constants';
import {
  BarContainer,
  StyledBar,
  StyledBarValue,
  StyledContainer,
  StyledEmptyBarValue,
  StyledLabel,
  StyledPercentageValue,
  StyledResultValue,
} from './styles';

const PositiveNegativeProgressBar = ({
  direction = 'horizontal',
  label = '',
  labelHeight = '60px',
  labelPosition = 'left',
  negativeValue = 0,
  positiveValue = 0,
  resultValue = '',
  resultColor,
  height = DEFAULT_BAR_ITEM_HEIGHT,
  width = DEFAULT_BAR_ITEM_WIDTH,
  showPercentageValue = false,
  onClick,
  originalPercentage,
  tooltipValue,
  ...otherProps
}) => {
  const hasLabel = label !== '';
  const hasResultValue = resultValue !== '';
  const isVertical = direction === 'vertical' ? 'true' : 'false';

  const renderBar = () => {
    return (
      <BarContainer height={height} width={width} isvertical={isVertical}>
        <StyledBar
          bartype="negative"
          value={negativeValue}
          isvertical={isVertical}
          position="relative"
        >
          <StyledEmptyBarValue className="emptyBarValue"></StyledEmptyBarValue>
          {!!negativeValue && <StyledBarValue
            position="relative"
            overflow="visible !important"
            className="barValue"
            sx={{ transition: 'all 300ms ease-out' }}
            alignItems="center"
            isRight
          >
            {showPercentageValue ? (
              <Typography variant="caption" fontWeight='bold' color='white' pr={1}>
                {`${negativeValue}%`}
              </Typography>
            ) : ''}
            {originalPercentage && negativeValue !== 0 && (
              <Typography
                position="absolute"
                bottom={isVertical === 'true' ? '-18px' : 'auto'}
                left={isVertical === 'true' ? 'auto' : '-18px'}
                width="fit-content"
                height="fit-content"
                variant="caption"
                display="flex"
                alignItems="center"
                fontSize="10px"
              >
                {originalPercentage}%
              </Typography>
            )}
          </StyledBarValue>}
          {!negativeValue && showPercentageValue && <Typography variant="caption">
            {`${negativeValue}%`}
          </Typography>}
        </StyledBar>
        <StyledBar
          bartype="positive"
          value={positiveValue}
          isvertical={isVertical}
          position="relative"
        >
          {!!positiveValue && <StyledEmptyBarValue
            position="relative"
            overflow="visible !important"
            className="barValue"
            sx={{ transition: 'all 300ms ease-out' }}
            alignItems="center"
          >
            {showPercentageValue ? (
              <Typography variant="caption" fontWeight='bold' color='white' pl={1}>
                {`${positiveValue}%`}
              </Typography>
            ) : ''}
            {originalPercentage && positiveValue !== 0 && (
              <Typography
                position="absolute"
                top={isVertical === 'true' ? '-18px' : 'auto'}
                right={isVertical === 'true' ? 'auto' : '-18px'}
                width="fit-content"
                height="fit-content"
                variant="caption"
                display="flex"
                alignItems="center"
                fontSize="10px"
              >
                {originalPercentage}%
              </Typography>
            )}
          </StyledEmptyBarValue>}
          {!positiveValue && showPercentageValue && <Typography variant="caption">
            {`${positiveValue}%`}
          </Typography>}
          <StyledEmptyBarValue className="emptyBarValue" />
        </StyledBar>
      </BarContainer>
    );
  };

  const renderResultValue = () => {
    return (
      <StyledResultValue variant="caption" resultvalue={resultValue} resultcolor={resultColor}>
        {typeof resultValue === 'number' ? `${resultValue}%` : resultValue}
      </StyledResultValue>
    );
  };

  /**HORIZONTAL BAR */
  if (direction === 'horizontal') {
    return (
      <StyledContainer width="100%" display="flex" labelPosition={labelPosition} {...otherProps}>
        {hasLabel && (
          <Tooltip title={tooltipValue || label}>
            <StyledLabel
              variant="caption"
              width={labelPosition === 'top' ? '100%' : '20%'}
              height={labelHeight}
              marginRight="10px"
              isvertical={isVertical}
              onClick={() => onClick(label)}
            >
              {label}
            </StyledLabel>
          </Tooltip>
        )}
        <Box
          flex={1}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <Box width="100%">{renderBar()}</Box>
          {hasResultValue && (
            <Box width="25%" maxWidth="60px" textAlign="left" padding="0 3px">
              {renderResultValue()}
            </Box>
          )}
        </Box>
      </StyledContainer>
    );
  }

  /**VERTICAL BAR */
  if (direction === 'vertical') {
    return (
      <StyledContainer isvertical={isVertical} {...otherProps}>
        {renderBar()}
        {label !== '' && (
          <Tooltip title={tooltipValue || label}>
            <StyledLabel
              variant="caption"
              isvertical={isVertical}
              height={labelHeight}
              onClick={() => onClick(label)}
            >
              {label}
            </StyledLabel>
          </Tooltip>
        )}
      </StyledContainer>
    );
  }

  return <></>;
};

PositiveNegativeProgressBar.propTypes = {
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
  label: PropTypes.string,
  labelHeight: PropTypes.string,
  labelPosition: PropTypes.oneOf(['top', 'left']),
  negativeValue: PropTypes.number,
  positiveValue: PropTypes.number,
  resultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  resultColor: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  showPercentageValue: PropTypes.bool,
  onClick: PropTypes.func,
};

export default PositiveNegativeProgressBar;
