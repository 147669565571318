import { call, fork, put, takeLatest } from 'redux-saga/effects';
import stocks from '../../services/stocks';

import {
  DAILY_STOCK_PRICES_ACTION,
  DAILY_STOCK_PRICES_END,
  DAILY_STOCK_PRICES_ERROR,
  DAILY_STOCK_PRICES_OK,
  RISK_INDICATOR_ACTION,
  RISK_INDICATOR_END,
  RISK_INDICATOR_ERROR,
  RISK_INDICATOR_OK,
  SEARCH_STOCK_ACTION,
  SEARCH_STOCK_END,
  SEARCH_STOCK_ERROR,
  SEARCH_STOCK_OK,
  SET_CURRENT_PAGE,
  STOCK_PRICES_ACTION,
  STOCK_PRICES_START,
  STOCK_PRICES_END,
  STOCK_PRICES_ERROR,
  STOCK_PRICES_OK,
} from '../constants/stock.constant';

export function* searchStocks(action) {
  try {
    const xhr = yield call(stocks.search, action.params);

    if (xhr.status >= 400) {
      throw xhr;
    }

    yield put({ type: SEARCH_STOCK_OK, ...xhr });
  } catch (e) {
    console.log('error : ', e);
    const { data } = e;

    yield put({ type: SEARCH_STOCK_ERROR, ...e });
  } finally {
    yield put({ type: SEARCH_STOCK_END });
  }
}

export function* searchStocksSaga() {
  yield takeLatest(SEARCH_STOCK_ACTION, searchStocks);
}

export function* dailyStock(action) {
  try {
    const xhr = yield call(stocks.dailyStockPrices, action.params);

    if (xhr.status >= 400) {
      throw xhr;
    }

    yield put({ type: DAILY_STOCK_PRICES_OK, ...xhr });
  } catch (e) {
    console.log('error : ', e);
    const { data } = e;

    yield put({ type: DAILY_STOCK_PRICES_ERROR, ...e });
  } finally {
    yield put({ type: DAILY_STOCK_PRICES_END });
  }
}

export function* stockPrices(action) {
  try {
    yield put({ type: STOCK_PRICES_START });
    const xhr = yield call(stocks.stockPrices, {
      market: action.market,
      timeFrame: action.timeFrame,
      params: action.params,
    });

    if (xhr.status >= 400) {
      throw xhr;
    }
    yield put({ type: STOCK_PRICES_OK, payload: { ...xhr, page: action.params.page } });
    yield put({ type: SET_CURRENT_PAGE, page: action.params.page });
  } catch (e) {
    console.log('error : ', e);
    const { data } = e;

    yield put({ type: STOCK_PRICES_ERROR, ...e });
  } finally {
    yield put({ type: STOCK_PRICES_END });
  }
}

export function* riskIndicator(action) {
  try {
    const xhr = yield call(stocks.riskIndicator, { timeFrame: action.timeFrame });

    if (xhr.status >= 400) {
      throw xhr;
    }

    yield put({ type: RISK_INDICATOR_OK, ...xhr });
  } catch (e) {
    console.log('error : ', e);
    const { data } = e;

    yield put({ type: RISK_INDICATOR_ERROR, ...e });
  } finally {
    yield put({ type: RISK_INDICATOR_END });
  }
}

export function* dailyStockSaga() {
  yield takeLatest(DAILY_STOCK_PRICES_ACTION, dailyStock);
}
export function* stockPricesSaga() {
  yield takeLatest(STOCK_PRICES_ACTION, stockPrices);
}
export function* riskIndicatorSaga() {
  yield takeLatest(RISK_INDICATOR_ACTION, riskIndicator);
}

const stocks_sagas = [
  fork(searchStocksSaga),
  fork(dailyStockSaga),
  fork(stockPricesSaga),
  fork(riskIndicatorSaga),
];
export default stocks_sagas;
